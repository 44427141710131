import React, { useState } from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { mapEdgesToNodes } from '../lib/helpers'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import LocationGalleryItem from '../components/modules/locationGalleryItem'
import GraphQLErrorList from '../components/graphql-error-list'
import theme from '../styles/theme'
import GalleryContainer from "../containers/gallery"

export const query = graphql`
  query GalleryPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPageTemplate(_id: { eq: "9196bef7-0917-4e14-93ac-6fe6c01c8242" }) {
      title
      header
    }
  }
`

const GalleryPage = props => {

  const { data, error } = props
  // console.log("locations data: ", data)

  if (error) {
    return (
      <Layout darkMode={true}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }


  const site = (data || {}).site
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout darkMode={true}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <GalleryContainer />
    </Layout>
  )
}

export default GalleryPage
